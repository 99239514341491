import React, { useEffect } from 'react'

// assets
import background from 'assets/images/background.png';

// global components
import Header from 'components/Header'
import Footer from 'components/Footer'

// local components
import Banner from './components/Banner'
import Contact from './components/Contact'
import AboutUs from './components/AboutUs';
import Promotions from './components/Promotions';
import Kits from './components/Kits.jsx';
import WhatsApp from 'components/Whatsapp';

function Home() {
  useEffect(() => {
    document.title = 'Empório Maziero';
  }, []);

  return (
    <main
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Header />
      <Banner />
      {/* Promoções */}
      {/* Encontre o Vinho Ideal ex: cards com faixas de preço */}
      {/* Nossos Kits */}
      {/* Vinhos */}
      <Promotions />
      <AboutUs />
      <Kits />
      <Contact />
      <Footer />
      {/* <CouponPopup /> */}
      <WhatsApp />
    </main>
  )
}

export default Home