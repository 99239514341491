import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NotFound from 'pages/NotFound/NotFound';
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import Sign from 'pages/Sign/Sign';
import Admin from 'pages/Admin/Admin';
import Products from 'pages/Products/Products';
import Product from 'pages/Product/Product';

const routes = createBrowserRouter([
  { path: '*', element: <NotFound /> },
  { path: '/', element: <Home /> },
  { path: '/sign', element: <Sign /> },
  { path: '/login', element: <Login /> },
  { path: '/admin', element: <Admin /> },
  { path: '/produtos', element: <Products /> },
  { path: '/produto', element: <Product /> },
]);

export default function App() {
  return <RouterProvider router={routes} />;
}

// import React from 'react';
// import { HashRouter, Routes, Route } from 'react-router-dom';
// import NotFound from 'pages/NotFound/NotFound';
// import Home from 'pages/Home/Home';
// import Login from 'pages/Login/Login';
// import Sign from 'pages/Sign/Sign';
// import Admin from 'pages/Admin/Admin';
// import Products from 'pages/Products/Products';
// import Product from 'pages/Product/Product';

// export default function App() {
//   return (
//     <HashRouter>
//       <Routes>
//         <Route path="*" element={<NotFound />} />
//         <Route path="/" element={<Home />} />
//         <Route path="/sign" element={<Sign />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/admin" element={<Admin />} />
//         <Route path="/produtos" element={<Products />} />
//         <Route path="/produto" element={<Product />} />
//       </Routes>
//     </HashRouter>
//   );
// }