import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductById } from 'services/productService';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Contact from 'pages/Home/components/Contact';

import API_BASE_URL from 'config/apiConfig';

const API_URL = `${API_BASE_URL}/uploads/`;

import vinho from 'assets/images/vinho.png'

const formatCurrency = (value) => {
  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) return 'R$0,00';

  const adjustedValue = numberValue / 100;

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(adjustedValue);
};

function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await getProductById(id);
        setProduct(data);
        setIsLoading(false);
      } catch (error) {
        setError('Erro ao carregar produto');
        setIsLoading(false);
      }
    };

    // fetchProduct();
  }, [id]);

  useEffect(() => {
    // document.title = product ? `Empório Maziero - ${product.name}` : 'Empório Maziero - Produto';
    document.title = product ? `Empório Maziero - ${product.name}` : 'Empório Maziero - Vinho X';
  }, [product]);

  // if (isLoading) return <p className="text-center text-lg">Carregando...</p>;
  if (error) return <p className="text-center text-lg text-red-500">{error}</p>;

  // Agrupando subcategorias por grupo
  const groupedSubcategories = product.product_subcategories.reduce((acc, item) => {
    const groupName = item.subcategories.subcategory_group.name;
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(item.subcategories);
    return acc;
  }, {});

  // Configurações do carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <main className="flex flex-col items-center w-full min-h-screen bg-gray-100">
      <Header />
      {product ? (
        <div className="flex flex-col items-center md:w-8/12">
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center w-full p-4">
            {/* <Slider {...settings}>
              {product.product_images.map((img) => (
                <picture key={img.id} className="w-full transition-opacity duration-500">
                  <img
                    src={`${API_URL}${img.image_url}`}
                    alt={product.name}
                    className="w-full h-96 object-cover rounded-lg"
                    style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.5s ease-in-out' }}
                    onLoad={() => setIsLoading(false)}
                  />
                </picture>
              ))}
            </Slider> */}

            <img src={vinho} alt="" className='mt-20' />

            <aside className='rounded-t-xl p-2'>  
              <div className='flex flex-col gap-1'>
                <h1 className="text-3xl font-semibold">Vinho X</h1>
                {/* <p className="text-3xl font-semibold text-red-500 mb-4">
                  {formatCurrency(product.promotional_price)}
                </p> */}
                <div className='flex flex-col mb-4'>
                  <p className="text-3xl font-semibold text-red-500">
                    R$300,00
                  </p>
                  <p>
                    em até 3x de R$100,00
                  </p>
                </div>
              </div>
              <button className="bg-green text-white text-lg p-3 rounded-lg w-9/12 md:w-1/2 hover:bg-green-600 transition duration-300">
                <a href="https://api.whatsapp.com/send?phone=5543996613706&text=Ol%C3%A1+gostaria+de+comprar%3A%0D%0A%0D%0A*Vinho X*%0D%0A*Pre%C3%A7o:*%20R%24300%2C00%0D%0A*Link:*%20https%3A%2F%2Fwww.mazieroemporio.com.br%2Fproduto%2Fvinho-x%2F%0D%0AObrigado%21" target='_blank' className='flex justify-center items-center gap-4'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                  </svg>
                  <span>
                    Solicitar via Whataapp
                  </span>
                </a>
              </button>
              {/* <p className="mt-4">{product.description}</p> */}
              <p className="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. In, voluptatum assumenda repudiandae veniam nisi nam doloremque id, ipsam expedita, cumque voluptates saepe rerum asperiores excepturi eos? Eius consequatur porro veniam.</p>

              <div className="subcategories mt-8">
                {Object.entries(groupedSubcategories).map(([groupName, subcategories]) => (
                  <div key={groupName} className="subcategory-group mb-6">
                    <p className="text-lg">
                      <strong>Categoria:</strong> {subcategories.map((sub) => sub.name).join(', ')}
                    </p>
                  </div>
                ))}
              </div>
            </aside>
          </div>
        </div>
      ) : (
        <p className="text-center text-lg">Produto não encontrado.</p>
      )}
      <Contact />
      <Footer />
    </main>
  );
}

export default Product;